@font-face {
  font-family: 'Futura Heavy';
  src: url("../fonts/FuturaStd-Heavy.otf");
}

@font-face {
  font-family: 'PT Serif Regular';
  src: url("../fonts/PTF55F.ttf");
}

@font-face {
  font-family: 'Futura Book';
  src: url("../fonts/FuturaStd-Book.otf");
}

* {
  box-sizing: border-box;
}

html {
  font-size: 112.5%;
  line-height: 1.33333em;
  font-family: "PT Serif Regular";
  font-size: 18px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

:link {
  color: #3c7e44;
  text-decoration: none;
}

:visited {
  color: #3c7e44;
}

a:hover,
a:focus {
  color: #56c663;
}

a:active {
  color: #3c7e44;
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 18px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 40px;
  font-size: 2.22222rem;
  line-height: 48px;
  line-height: 2.66667rem;
  margin-top: 24px;
  margin-top: 1.33333rem;
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
  font-family: 'Futura Heavy';
}

h2 {
  font-size: 24px;
  font-size: 1.33333rem;
  line-height: 36px;
  line-height: 2rem;
  margin-top: 24px;
  margin-top: 1.33333rem;
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
  font-family: 'Futura Heavy';
  text-transform: uppercase;
}

h3 {
  font-size: 20px;
  font-size: 1.11111rem;
  line-height: 36px;
  line-height: 2rem;
  margin-top: 24px;
  margin-top: 1.33333rem;
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
}

h4 {
  font-size: 18px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.33333rem;
  margin-top: 24px;
  margin-top: 1.33333rem;
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
}

h5 {
  font-size: 15px;
  font-size: 0.83333rem;
  line-height: 24px;
  line-height: 1.33333rem;
  margin-top: 24px;
  margin-top: 1.33333rem;
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
}

h6 {
  font-size: 12px;
  font-size: 0.66667rem;
  line-height: 24px;
  line-height: 1.33333rem;
  margin-top: 24px;
  margin-top: 1.33333rem;
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
}

abbr[title] {
  border-bottom: 1px dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #fd0;
  color: #000;
}

small {
  font-size: 15px;
  font-size: 0.83333rem;
}

sub,
sup {
  font-size: 12px;
  font-size: 0.66667rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

.divider,
hr {
  margin: 24px 0px;
  margin: 1.33333rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 24px;
  margin-top: 1.33333rem;
}

dl,
menu,
ol,
ul {
  margin: 24px 0px;
  margin: 1.33333rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 22px;
}

[dir="rtl"] dd {
  margin: 0 22px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 22px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 22px 0 0;
}

figcaption, blockquote {
  padding-left: 40px;
  margin-left: 0;
  color: #f47b3d;
  font-style: italic;
  font-size: 15px;
  line-height: 1.6em;
  border-left: 5px solid #f47b3d;
}

figure {
  margin: 24px 0px;
  margin: 1.33333rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

p,
pre {
  margin: 24px 0px;
  margin: 1.33333rem 0;
}

code,
kbd,
samp,
var, pre {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

pre {
  overflow: auto;
}

img {
  border: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

.lt-ie9 img, .lt-ie9
svg {
  width: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.button,
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -moz-appearance: button;
  -webkit-appearance: button;
  display: inline-block;
  padding: 5px 20px;
  line-height: inherit;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: #f0edec;
  background-color: #56c663;
  border-radius: 5px;
  border: 0;
  margin: 20px;
  font-family: 'Futura Heavy';
}

.button:hover, .button:focus, .button:active,
button:hover,
[type="button"]:hover,
[type="reset"]:hover,
[type="submit"]:hover,
button:focus,
[type="button"]:focus,
[type="reset"]:focus,
[type="submit"]:focus,
button:active,
[type="button"]:active,
[type="reset"]:active,
[type="submit"]:active {
  text-decoration: none;
  background-color: #3c7e44;
}

.button[disabled],
button[disabled],
[disabled][type="button"],
[disabled][type="reset"],
[disabled][type="submit"] {
  cursor: default;
  color: #999999;
}

.button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active,
button[disabled]:hover,
[disabled][type="button"]:hover,
[disabled][type="reset"]:hover,
[disabled][type="submit"]:hover,
button[disabled]:focus,
[disabled][type="button"]:focus,
[disabled][type="reset"]:focus,
[disabled][type="submit"]:focus,
button[disabled]:active,
[disabled][type="button"]:active,
[disabled][type="reset"]:active,
[disabled][type="submit"]:active {
  color: #999999;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

html input[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="text"], input[type="search"], input[type="tel"], input[type="url"], input[type="email"], input[type="password"], input[type="date"], input[type="time"], input[type="number"], textarea {
  line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 8.4px 15px 15.6px;
  padding: 0.46667rem 0.83333rem 0.86667rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  border: 0;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

select {
  border-radius: 5px;
  font-size: 15px;
  font-family: 'Futura Book';
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 24px 0px;
  margin: 1.33333rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table .image-field-caption {
  display: none;
}

td,
th {
  padding: 0;
}

tr {
  border-bottom: 1px solid #dbd8d7;
  padding-bottom: 5px;
}

table.views-view-grid tr {
  border-bottom: none;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/components/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item--is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/components/autocomplete/throbber-active.gif);
}

.box {
  margin-bottom: 24px;
  margin-bottom: 1.33333rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #3c7e44;
}

.visually-hidden,
.visually-hidden--focusable,
.element-focusable,
.element-invisible,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.clearfix:before,
.header:before,
.tabs:before,
.tabs--secondary:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after,
.tabs--secondary:after {
  content: '';
  display: table;
  clear: both;
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/components/collapsible-fieldset/expanded.png);
  background-image: url(../sass/components/collapsible-fieldset/expanded.svg), none;
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: 98% 50%;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed__wrapper, .collapsible-fieldset--is-collapsed__wrapper,
.is-collapsed__wrapper.collapsible,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed__legend, .collapsible-fieldset--is-collapsed__legend,
.is-collapsed__legend.collapsible,
.collapsible.collapsed .fieldset-legend {
  background-image: url(../sass/components/collapsible-fieldset/collapsed.png);
  background-image: url(../sass/components/collapsible-fieldset/collapsed.svg), none;
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend,
[dir="rtl"] .is-collapsed__legend.collapsible,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend {
  background-image: url(../sass/components/collapsible-fieldset/collapsed-rtl.png);
  background-image: url(../sass/components/collapsible-fieldset/collapsed-rtl.svg), none;
  background-position: 98% 50%;
  background-position: right 4px top 50%;
}

.comment__section,
.comments {
  margin: 24px 0px;
  margin: 1.33333rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 22px;
  padding-left: 15px;
  border-left: 1px solid #838384;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 22px;
}

.comment .links a:link,
.comment .links a:visited {
  color: #56c663;
}

.comment .links a:hover {
  color: #3c7e44;
}

.comment .username {
  font-weight: bold;
}

.form-item {
  margin: 24px 0px;
  margin: 1.33333rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 15px;
  font-size: 0.83333rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio__label,
.form-item--radio .form-item__label,
label.option, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label {
  display: inline;
  font-weight: normal;
}

.form-item--radio__description,
.form-item--radio .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-item--radio .form-item .description,
.form-item
.form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: rgba(49, 53, 52, 0.5);
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: rgba(49, 53, 52, 0.5);
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

header.header {
  background-color: #3c7e44;
  border-top: 1px solid #838384;
  border-right: 1px solid #838384;
  position: relative;
  transition: all 1s;
  height: 74px;
}

.header__logo {
  float: left;
  padding: 0;
  display: block;
  margin-left: 9%;
  width: 25%;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  position: absolute;
  right: 0;
  z-index: 1000;
  background-color: #313534;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__secondary-menu li {
  float: left;
}

.header__region {
  background-color: #313534;
  clear: none;
  float: right;
  padding-left: 72px;
  position: relative;
}

.header__region::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 72px solid #3c7e44;
  border-right: 72px solid transparent;
  position: absolute;
  left: 0;
}

.header .menu,
.header .sf-menu.menu {
  margin: 24px 0px;
  margin: 1.33333rem 0;
  height: 25px;
  line-height: 25px;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 24px 0px;
  margin: 1.33333rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

.messages--error__highlighted-text,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/components/more-link/more-link__help-icon.png);
  background-image: url(../sass/components/more-link/more-link__help-icon.svg), none;
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-type: none;
  padding-right: 75px;
  text-transform: uppercase;
}

.lt-ie9 .nav-menu__item,
.lt-ie9 .menu__item {
  list-style-image: url(../sass/components/nav-menu/leaf.png);
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/components/nav-menu/expanded.svg);
  list-style-type: circle;
}

.lt-ie9 .nav-menu__item.is-expanded, .lt-ie9 .nav-menu__item--is-expanded,
.lt-ie9 .is-expanded.menu__item,
.lt-ie9 .menu__item.is-expanded {
  list-style-image: url(../sass/components/nav-menu/expanded.png);
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed.svg);
  list-style-type: disc;
}

.lt-ie9 .nav-menu__item.is-collapsed, .lt-ie9 .nav-menu__item--is-collapsed,
.lt-ie9 .is-collapsed.menu__item,
.lt-ie9 .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed.png);
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed-rtl.svg);
}

.lt-ie9 [dir="rtl"] .nav-menu__item.is-collapsed, .lt-ie9 [dir="rtl"] .nav-menu__item--is-collapsed,
.lt-ie9 [dir="rtl"] .is-collapsed.menu__item,
.lt-ie9 [dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed-rtl.png);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #3c7e44;
}

.menu__item {
  font-family: 'Futura Heavy';
}

.menu a,
.links a {
  color: #edf4d3;
}

#menu-424-1 > a {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/home.svg") no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.menu-2 > a {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/user.svg") no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.menu-503 > a {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/search.svg") no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  background-color: #f0edec;
  padding: 20px;
  box-shadow: 0 3px 5px #313534;
}

.sf-menu li:hover > ul a:link,
.sf-menu li:hover > ul a:visited,
.sf-menu li.sfHover > ul a:link,
.sf-menu li.sfHover > ul a:visited {
  font-family: 'Futura Book';
  color: #313534;
  text-transform: capitalize;
  padding: 5px;
}

.sf-menu li:hover > ul a:hover,
.sf-menu li.sfHover > ul a:hover {
  background-color: #56c663;
  color: #edf4d3;
}

.sf-with-ul {
  position: relative;
}

.sfHover .sf-with-ul::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  bottom: -20px;
  border-bottom: 10px solid #f0edec;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  box-shadow: 0 0 10px #313534;
}

.sf-menu h3 {
  font-size: 12px;
  color: #3c7e44;
  border-bottom: 1px solid #3c7e44;
  margin-bottom: 10px;
}

a > .sf-sub-indicator {
  top: 6%;
  right: -1em;
  background: url("/sites/all/themes/pr_theme/images/down.svg") 0 0 no-repeat;
  height: 18px;
  width: 18px;
}

li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator {
  background-position: 0 0;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/components/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/components/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/components/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  background: url(../sass/components/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle-icon--is-hover,
.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/components/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/components/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.tabs,
.tabs--secondary {
  margin: 18px 0px 0px;
  margin: 1rem 0 0;
  line-height: 30px;
  line-height: 1.66667rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs,
  .tabs--secondary {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link-common,
.tabs__tab-link,
.tabs--secondary__tab-link {
  padding: 0px 24px;
  padding: 0 1.33333rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
}

.tabs__tab-link {
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link.is-active, .tabs__tab-link--is-active,
.tabs__tab-link:active {
  background: #fff;
  text-shadow: none;
}

.tabs--secondary {
  margin-top: 24px;
  margin-top: 1.33333rem;
  font-size: 15px;
  font-size: 0.83333rem;
  background-image: none;
}

.tabs + .tabs--secondary, .tabs--secondary + .tabs--secondary {
  margin-top: 0px;
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary__tab {
  margin: 9px 3px;
  margin: 0.5rem 0.16667rem;
  float: left;
}

[dir="rtl"] .tabs--secondary__tab {
  float: right;
}

.tabs--secondary__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
}

.tabs--secondary__tab-link:focus, .tabs--secondary__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary__tab-link.is-active, .tabs--secondary__tab-link--is-active,
.tabs--secondary__tab-link:active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.watermark {
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.more-link {
  text-align: center;
  border-top: 1px solid white;
}

.more-link a {
  font-family: 'Futura Heavy';
  color: white;
  font-size: 12px;
  text-transform: uppercase;
}

.region-top {
  position: relative;
  margin: 20px auto;
  max-width: 1180px;
}

.region-top #block-simpleads-ad-groups-117 {
  overflow: hidden;
  transition: 1s all;
}

.region-top #block-simpleads-ad-groups-117 img {
  transition: 1s all;
  top: -75px;
}

.region-top #block-block-5 {
  position: absolute;
  right: 20px;
  z-index: 100;
  top: 10%;
  background: #f0edec;
  padding: 10px 20px;
  cursor: pointer;
  color: #c00;
  border: 5px solid #56c663;
  font-style: italic;
  font-family: 'Futura Heavy';
}

.breadcrumb {
  border-bottom: 1px solid #838384;
  padding-bottom: 5px;
}

body {
  background-color: #313534;
}

body .header__region .menu li {
  float: left;
}

body .layout-center {
  padding: 0;
  max-width: 1440px;
  overflow-x: hidden;
}

body .layout-center .layout-3col {
  background-color: #f0edec;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 1s;
  position: relative;
  left: 0;
}

body .layout-center .layout-3col .panels-flexible-column-2-main h2, body .layout-center .layout-3col .panels-flexible-column-pr_main_layout-main h2, body .layout-center .layout-3col .panels-flexible-column-2-main h2 a {
  border-top: 1px solid #838384;
  border-bottom: 1px solid #838384;
  color: #3c7e44;
}

body .layout-center .layout-3col .panels-flexible-column-2-main h2 a {
  display: inline-block;
  width: 100%;
  margin: -1px auto;
}

body .layout-center .layout-3col__full {
  padding: 0;
  transition: all 1s;
  max-width: 1180px;
  margin: 0 auto;
  float: none;
}

body footer {
  background-color: #dbd8d7;
  padding: 20px 10%;
  text-align: center;
  font-size: 15px;
}

body footer h2 {
  text-align: center;
  display: table;
  width: 100%;
  font-size: 18px;
}

body footer h2 > span,
body footer h2:before,
body footer h2:after {
  display: table-cell;
}

body footer h2:before,
body footer h2:after {
  /*background: url(http://dummyimage.com/2x1/838384/fff&text=+) repeat-x center; */
  width: 50%;
  content: ' ';
}

body footer h2 > span {
  white-space: nowrap;
  padding: 0 15px;
}

body footer .view-partner-logo ul li {
  display: inline-block;
  list-style-type: none;
  margin: 0 20px;
}

body .region-bottom {
  background-color: #838384;
  padding: 20px 10%;
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
}

.view-article-slideshow {
  position: relative;
  max-width: 850px;
  margin: 0 auto;
}

.view-article-slideshow .views-slideshow-controls-top span {
  display: block;
  height: 85px;
  padding: 0;
  position: absolute;
  top: 40%;
  transition: all .2s linear;
  width: 70px;
  z-index: 10;
}

.view-article-slideshow .views-slideshow-controls-top span a {
  display: none;
}

.view-article-slideshow .views-slideshow-controls-top .views_slideshow_controls_text_previous {
  background: url("/sites/all/themes/pr_theme/images/slideshow-left.svg") no-repeat;
  left: -8px;
}

.view-article-slideshow .views-slideshow-controls-top .views_slideshow_controls_text_pause {
  display: none;
}

.view-article-slideshow .views-slideshow-controls-top .views_slideshow_controls_text_next {
  background: url("/sites/all/themes/pr_theme/images/slideshow-right.svg") no-repeat;
  right: -20px;
}

.view-article-slideshow .views-slideshow-controls-bottom {
  text-align: center;
  background-color: rgba(49, 53, 52, 0.5);
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 10;
}

.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field {
  display: inline-block;
}

.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .views_slideshow_pager_field_item {
  display: inline-block;
  cursor: pointer;
  line-height: 0;
  overflow: hidden;
  position: relative;
  margin: 10px 0;
}

.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .views_slideshow_pager_field_item .views-field-nid {
  display: block;
  height: 18px;
  width: 18px;
  float: left;
  background-color: #f0edec;
  margin-left: 20px;
  border-radius: 18px;
}

.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .views_slideshow_pager_field_item .views-field-nid a {
  display: none;
}

.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .active .views-field-nid,
.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .views-field-nid:hover {
  background-color: #56c663;
}

.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .views_slideshow_pager_field_item:hover,
.view-article-slideshow .views-slideshow-controls-bottom .views_slideshow_pager_field .views_slideshow_pager_field_item.active {
  overflow: visible;
}

.view-article-slideshow .views-slideshow-cycle-main-frame {
  width: 100%;
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-title {
  bottom: 80px;
  font-size: 40px;
  position: absolute;
  line-height: normal;
  padding: 0 50px;
  font-family: 'Futura Heavy';
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-title a:link,
.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-title a:visited {
  color: #f0edec;
  background-color: rgba(49, 53, 52, 0.5);
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-title:hover a {
  color: #56c663;
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-body {
  position: absolute;
  bottom: 25px;
  padding: 0 50px;
  color: #f0edec;
  margin: 0;
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-body p {
  background-color: rgba(49, 53, 52, 0.5);
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .image-field-caption {
  display: none;
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-field-produce {
  font-family: 'Futura Heavy';
  bottom: 190px;
  padding: 5px;
  margin-left: 50px;
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  background-color: #56c663;
  color: #f0edec;
  border-radius: 5px;
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-field-produce a:link,
.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-field-produce a:visited {
  color: #f0edec;
}

.view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-field-produce:hover {
  background-color: #3c7e44;
}

.pane-quicktabs-latest-popular-articles,
.pane-block-6 {
  background-color: #f2a42d;
  border-radius: 5px;
}

.pane-block-6 {
  position: relative;
}

.pane-block-6 form {
  padding: 20px;
}

.pane-block-6 .button {
  width: 35px;
  height: 35px;
  font-size: 38px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  top: -40px;
  right: -205px;
  position: relative;
}

.pane-block-6 input[type="email"] {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  font-family: 'Futura Book';
}

.pane-featured-country-panel-pane-1 {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.pane-featured-country-panel-pane-1 h2 {
  display: none;
}

.pane-featured-country-panel-pane-1 .views-field-name {
  font-family: 'Futura Heavy';
  background-color: #313534;
  width: 100%;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
}

.pane-featured-country-panel-pane-1 .views-field-title {
  position: absolute;
  background-color: rgba(49, 53, 52, 0.5);
  width: 100%;
  text-align: center;
  padding: 10px;
  bottom: 0;
}

.pane-featured-country-panel-pane-1 .views-field-field-region-image .field-content {
  line-height: 0;
}

.pane-featured-country-panel-pane-1 img {
  width: 100%;
}

.pane-featured-country-panel-pane-1 a:link,
.pane-featured-country-panel-pane-1 a:visited {
  color: #f0edec;
}

.quicktabs-wrapper {
  padding: 20px 0 5px;
}

.quicktabs-wrapper .item-list {
  margin: 0 20px;
}

.quicktabs-wrapper ul {
  border: 1px solid #f0edec;
  text-transform: uppercase;
  font-family: 'Futura Heavy';
  padding: 0;
  height: 29px;
}

.quicktabs-wrapper ul li {
  float: left;
  text-align: center;
  font-size: 15px;
}

.quicktabs-wrapper ul li a:link,
.quicktabs-wrapper ul li a:visited {
  color: #313534;
}

.quicktabs-wrapper ul li.active {
  position: relative;
  background-color: #f0edec;
}

.quicktabs-wrapper ul li.active::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 16px solid #f0edec;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  position: absolute;
  left: 40%;
  top: 20px;
}

.quicktabs-wrapper ul li:nth-child(1) {
  width: 45%;
}

.quicktabs-wrapper ul li:nth-child(2) {
  width: 55%;
  position: relative;
}

.quicktabs-wrapper ul li:nth-child(2)::before {
  content: '';
  width: 0;
  height: 0;
  border-right: 28px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
}

.quicktabs-wrapper ul li:nth-child(2):not(.active)::before {
  border-top: 28px solid #f0edec;
}

.quicktabs-wrapper ul li:nth-child(2).active::before {
  border-top: 28px solid #f2a42d;
}

.quicktabs-wrapper ol {
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;
}

.quicktabs-wrapper ol > li::before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -2em;
  box-sizing: border-box;
  width: 1.4em;
  height: 1.4em;
  border-top: 1px solid #313534;
  color: #f0edec;
  background-color: #313534;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
}

.quicktabs-wrapper ol > li {
  margin-bottom: 15px;
  position: relative;
  margin: 0 0 6px 2em;
  padding: 5px 0;
  list-style: none;
}

.quicktabs-wrapper ol > li .views-field-created {
  font-size: 15px;
  color: #313534;
  text-transform: uppercase;
}

.quicktabs-wrapper ol > li .views-field-title a:link,
.quicktabs-wrapper ol > li .views-field-title a:visited {
  color: #000;
}

.quicktabs-wrapper ol > li .views-field-title a:hover {
  color: #f0edec;
}

.quicktabs-wrapper .view-footer {
  border-top: 1px solid #f0edec;
  font-size: 12px;
}

.quicktabs-wrapper .view-footer p {
  margin: 5px 0 0;
  text-align: center;
}

.quicktabs-wrapper .view-footer p a:link,
.quicktabs-wrapper .view-footer p a:visited {
  color: #f0edec;
  font-family: 'Futura Heavy';
  text-transform: uppercase;
}

.quicktabs-wrapper .view-footer p a:hover {
  color: #3c7e44;
}

.view-featured-produce .view-content {
  overflow: hidden;
}

.view-featured-produce .views-row {
  width: 47.5%;
  float: left;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.view-featured-produce .views-row .views-field-field-image {
  float: left;
  margin-right: 15px;
}

.view-featured-produce .views-row .views-field-name {
  text-transform: uppercase;
  font-family: 'Futura Heavy';
  font-size: 15px;
}

.view-featured-produce .views-row .views-field-name a {
  color: #56c663;
}

.view-featured-produce .views-row .views-field-name a:hover {
  color: #3c7e44;
}

.view-featured-produce .views-row .views-field-title {
  font-weight: bold;
}

.view-featured-produce .views-row .views-field-title a {
  color: #313534;
}

.view-featured-produce .views-row .views-field-title a:hover {
  color: #56c663;
}

.view-featured-produce .views-row-odd {
  border-right: 1px solid #838384;
  margin-right: 5%;
}

.pane-featured-topic-panel-pane-1 {
  width: 33%;
  float: left;
  padding: 0 15px;
  min-height: 360px;
}

.pane-featured-topic-panel-pane-1 h2 {
  margin: 0;
}

.pane-featured-topic-panel-pane-1 .image-field-caption {
  display: none;
}

.pane-featured-topic-panel-pane-1 .views-row {
  list-style: square;
  padding-bottom: 15px;
  overflow: hidden;
}

.pane-featured-topic-panel-pane-1 .views-row {
  list-style: none;
  clear: both;
  margin-bottom: 10px;
  position: relative;
  left: -20px;
}

.pane-featured-topic-panel-pane-1 .views-row .views-field-field-image {
  display: block;
  float: left;
  margin-right: 15px;
}

.pane-featured-topic-panel-pane-1 a:link,
.pane-featured-topic-panel-pane-1 a:visited {
  color: #313534;
  font-size: 17px;
}

.pane-featured-topic-panel-pane-1 a:hover {
  color: #3c7e44;
}

.pane-featured-topic-panel-pane-1:nth-child(2), .pane-featured-topic-panel-pane-1:nth-child(5), .pane-featured-topic-panel-pane-1:nth-child(8) {
  border-left: 1px solid #838384;
  border-right: 1px solid #838384;
}

.pane-featured-topic-panel-pane-1:nth-child(1), .pane-featured-topic-panel-pane-1:nth-child(4), .pane-featured-topic-panel-pane-1:nth-child(4) {
  padding-left: 0;
}

.pane-featured-topic-panel-pane-1:nth-child(3), .pane-featured-topic-panel-pane-1:nth-child(6), .pane-featured-topic-panel-pane-1:nth-child(9) {
  padding-right: 0;
}

.pane-recommended-reads-panel-pane-1 .view-content, .view-recommended-reads .view-content {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  -moz-column-gap: 10px;
  /* Firefox */
  -webkit-column-gap: 10px;
  /* Safari and Chrome */
}

@media all and (max-width: 850px) {
  .pane-recommended-reads-panel-pane-1 .view-content, .view-recommended-reads .view-content {
    grid-template-columns: 50% 50%;
  }
}

@media all and (max-width: 442px) {
  .pane-recommended-reads-panel-pane-1 .view-content, .view-recommended-reads .view-content {
    grid-template-columns: 100%;
  }
}

.pane-recommended-reads-panel-pane-1 .views-row, .view-recommended-reads .views-row {
  margin-bottom: 10px;
}

.pane-recommended-reads-panel-pane-1 .image-field-caption, .view-recommended-reads .image-field-caption {
  display: none;
}

.pane-node-created {
  display: inline-block;
  margin-right: 20px;
  font-size: 15px;
}

.pane-node-created::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("/sites/all/themes/pr_theme/images/clock.svg") no-repeat 0 4px;
  position: relative;
  top: 4px;
}

.pane-node-author {
  display: inline-block;
  font-size: 15px;
}

.pane-node-author::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("/sites/all/themes/pr_theme/images/author.svg") no-repeat 0 4px;
  position: relative;
  top: 2px;
}

.pane-node-field-image {
  margin-top: 20px;
}

.node-type-article .image-field-caption {
  margin: 0;
  font-style: italic;
  font-size: 15px;
}

.node-type-article .image-field-caption p {
  margin: 5px 0;
}

.node-type-article .pane-node-body {
  width: 93%;
  float: right;
}

.node-type-article .pane-node-links {
  width: 40px;
  float: left;
  background-color: #f47b3d;
  margin-top: 64px;
  position: relative;
}

.node-type-article .pane-node-links li {
  display: inline-block;
  margin-left: 10px;
  padding: 0;
  margin-bottom: 10px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.node-type-article .pane-node-links li img {
  width: 22px;
  height: 22px;
  margin-bottom: 10px;
}

.node-type-article .pane-node-links::before {
  border-bottom: 40px solid #f47b3d;
  content: '';
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  position: absolute;
  left: 0;
  top: -40px;
}

.node-type-article .pane-node-links::after {
  border-top: 40px solid #f47b3d;
  content: '';
  width: 0;
  height: 0;
  border-right: 40px solid transparent;
  position: absolute;
  left: 0;
  bottom: -40px;
}

.node-type-article .pane-node-links .node-readmore,
.node-type-article .pane-node-links .comment-add,
.node-type-article .pane-node-links .comment_forbidden {
  display: none;
}

.node-type-article .pane-node-links .comment-comments > a {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/comment_add.svg") no-repeat;
  width: 24px;
  height: 48px;
  padding-top: 24px;
  overflow: hidden;
  padding-left: 5px;
  font-size: 15px;
}

.node-type-article .pane-node-links .print_html > a {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/print.svg") no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.node-type-article .pane-node-links .print_mail > a {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/email.svg") no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.node-type-article .pane-node-links .statistics_counter > span {
  display: block;
  background: url("/sites/all/themes/pr_theme/images/eye.svg") no-repeat;
  width: 24px;
  height: 48px;
  color: #f0edec;
  padding-top: 24px;
  overflow: hidden;
  font-size: 15px;
  padding-left: 3px;
}

.node-type-article .pane-node-field-image.bottom .field-item {
  display: inline-block;
  margin-right: 10px;
}

.node-type-article .pane-node-field-image.bottom .image-field-caption {
  display: none;
}

.node-type-article .field-type-taxonomy-term-reference .field-item {
  display: inline-block;
  margin-right: 10px;
}

.node-type-article .pane-entity-field {
  margin-bottom: 15px;
}

.page-taxonomy-term .view-article-by-term,
.view-produce-listing,
.view-latest-article {
  position: relative;
}

.page-taxonomy-term .view-article-by-term .view-header,
.view-produce-listing .view-header,
.view-latest-article .view-header {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #f0edec;
  text-transform: uppercase;
  font-family: 'Futura Heavy';
}

.page-taxonomy-term .view-article-by-term .views-exposed-form,
.view-produce-listing .views-exposed-form,
.view-latest-article .views-exposed-form {
  background-color: #3c7e44;
}

.page-taxonomy-term .view-article-by-term .views-exposed-form .views-widget-sort-by,
.view-produce-listing .views-exposed-form .views-widget-sort-by,
.view-latest-article .views-exposed-form .views-widget-sort-by {
  display: none;
}

.page-taxonomy-term .view-article-by-term .views-exposed-form .views-widget-sort-order, .page-taxonomy-term .view-article-by-term .views-exposed-form .views-exposed-widget,
.view-produce-listing .views-exposed-form .views-widget-sort-order,
.view-produce-listing .views-exposed-form .views-exposed-widget,
.view-latest-article .views-exposed-form .views-widget-sort-order,
.view-latest-article .views-exposed-form .views-exposed-widget {
  float: right;
  padding: 15px;
}

.page-taxonomy-term .view-article-by-term .views-exposed-form label,
.view-produce-listing .views-exposed-form label,
.view-latest-article .views-exposed-form label {
  float: left;
  color: #f0edec;
  margin-right: 15px;
  display: none;
}

.page-taxonomy-term table td, .view-latest-article table td, .view-all-articles table td, page-taxonomy-term table td {
  padding: 15px 0;
}

.page-taxonomy-term table a, .view-latest-article table a, .view-all-articles table a, page-taxonomy-term table a {
  display: block;
}

.page-taxonomy-term table .views-field-field-produce, .page-taxonomy-term table .views-field-title, .view-latest-article table .views-field-field-produce, .view-latest-article table .views-field-title, .view-all-articles table .views-field-field-produce, .view-all-articles table .views-field-title, page-taxonomy-term table .views-field-field-produce, page-taxonomy-term table .views-field-title {
  width: 60% !important;
  border-right: 1px solid #dbd8d7;
  padding-right: 20px;
}

.page-taxonomy-term table a:nth-child(1), .view-latest-article table a:nth-child(1), .view-all-articles table a:nth-child(1), page-taxonomy-term table a:nth-child(1) {
  font-family: 'Futura Heavy';
  font-size: 20px;
  color: #313534;
}

.page-taxonomy-term table a:nth-child(1):hover, .view-latest-article table a:nth-child(1):hover, .view-all-articles table a:nth-child(1):hover, page-taxonomy-term table a:nth-child(1):hover {
  color: #56c663;
}

.page-taxonomy-term table .views-field-created, .view-latest-article table .views-field-created, .view-all-articles table .views-field-created, page-taxonomy-term table .views-field-created {
  text-transform: uppercase;
  font-family: 'Futura Book';
  font-size: 15px;
  padding-left: 20px;
  width: 25%;
}

.page-taxonomy-term table .views-field-field-image, .view-latest-article table .views-field-field-image, .view-all-articles table .views-field-field-image, page-taxonomy-term table .views-field-field-image {
  width: 15%;
}

.block-simpleads {
  display: inline-block;
}

#block-simpleads-ad-groups-118 {
  margin-right: 15px;
  margin-bottom: 15px;
}

.panels-flexible-column-last .pane-block,
.panels-flexible-column-last .panel-pane {
  margin-bottom: 15px;
}

.region-navigation {
  overflow-y: scroll;
  height: 800px;
  width: 300px;
  position: absolute;
  border-top: 1px solid #838384;
  z-index: -1;
  top: -1px;
  visibility: hidden;
}

.region-navigation::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

#drawer-toggle {
  display: none;
}

#drawer-toggle-label {
  user-select: none;
  height: 50px;
  width: 50px;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 1s;
  left: 80px;
  top: 10px;
  cursor: pointer;
  float: left;
}

/* adds our "hamburger" menu icon */
#drawer-toggle-label:before {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 24px;
  background: #f0edec;
  left: 13px;
  top: 18px;
  box-shadow: 0 6px 0 #f0edec, 0 12px 0 #f0edec;
}

#drawer-toggle:checked ~ #drawer-toggle-label {
  background: #313534;
}

#drawer-toggle:checked ~ .sticky-wrapper header.header .region-navigation {
  z-index: 1000;
  transition: 1.5s all;
  left: -299px;
  visibility: visible;
}

#drawer-toggle:checked ~ .layout-3col,
#drawer-toggle:checked ~ .sticky-wrapper header {
  left: 300px;
}

#drawer-toggle:checked ~ .layout-3col main,
#drawer-toggle:checked ~ .sticky-wrapper header main {
  opacity: .5;
}

.region-navigation #block-block-3 {
  background-color: #3c7e44;
  padding: 20px;
}

.region-navigation #block-block-3 p {
  margin: 0;
}

.region-navigation #block-search-form {
  background-color: #3c7e44;
  padding: 20px;
}

.region-navigation #block-search-form .form-item {
  margin: 0;
}

.region-navigation #block-search-form .form-submit {
  display: none;
}

.region-navigation #block-search-form .form-text {
  color: #666666;
  display: block;
  height: 30px;
  line-height: 20px;
  background: #fff url("/sites/all/themes/pr_theme/images/search.svg") no-repeat scroll 3px 3px;
  padding: 5px;
  border: 1px solid #f47b3d;
  border-radius: 5px;
  padding-left: 25px;
  margin: 0 auto;
}

.region-navigation .easytree-title {
  color: #edf4d3;
  font-family: 'Futura Book';
  text-transform: capitalize;
}

.region-navigation .block-menu > ul > li > span > .easytree-title {
  font-family: 'Futura Heavy';
}

#block-menu-menu-drawer-menu > ul > li > span > .easytree-title {
  text-transform: uppercase;
}

#block-menu-menu-drawer-menu .easytree-container {
  background-color: #3c7e44;
  padding: 0;
}

#block-menu-menu-extra-drawer-menu .easytree-container {
  background-color: #313534;
  padding: 0;
}

.region-navigation span.easytree-expander {
  position: absolute;
  right: 10px;
  background: url("/sites/all/themes/pr_theme/images/plus.svg") 0 0;
  width: 22px;
  height: 22px;
}

.region-navigation span.easytree-expander:hover {
  background-position: 0;
}

.region-navigation .easytree-exp-e span.easytree-expander {
  position: absolute;
  right: 10px;
  background: url("/sites/all/themes/pr_theme/images/minus.svg") 0 0;
  width: 22px;
  height: 22px;
}

.region-navigation .easytree-exp-e span.easytree-expander:hover {
  background-position: 0;
}

.region-navigation .easytree-container.easytree-focused span.easytree-node.easytree-active,
.region-navigation .easytree-container.easytree-focused span.easytree-node.easytree-selected,
.region-navigation span.easytree-node.easytree-active:hover,
.region-navigation span.easytree-node.easytree-selected:hover {
  background-color: transparent;
  border-color: #56c663;
}

.region-navigation span.easytree-node {
  display: block;
  border: none;
  border-bottom: 1px solid #838384;
  padding: 10px;
  width: initial;
}

.region-navigation span.easytree-node:hover {
  background-color: transparent;
}

.region-navigation .block-block {
  color: #838384;
  text-align: center;
  font-style: italic;
}

.pane-right-side-bar h2 {
  color: #f0edec;
  text-align: center;
  margin: 0;
  border-bottom: 1px solid #f0edec;
  padding: 5px;
  font-size: 15px;
}

.pane-quicktabs-produce-marketplace {
  background: #f47b3d;
  border-radius: 5px;
  max-width: 300px;
}

.pane-quicktabs-produce-marketplace .quicktabs-wrapper ul li:nth-child(2).active::before {
  border-top: 28px solid #f47b3d;
}

.pane-upcoming-events-panel-pane-1 {
  max-width: 300px;
  background: #56c663;
  border-radius: 5px;
}

.pane-upcoming-events-panel-pane-1 .view-upcoming-events {
  padding: 20px;
}

.pane-upcoming-events-panel-pane-1 .view-upcoming-events .views-row {
  margin-bottom: 15px;
  overflow: hidden;
}

.pane-upcoming-events-panel-pane-1 .views-field-field-event-logo {
  float: right;
}

.pane-upcoming-events-panel-pane-1 .views-field-field-date {
  font-family: 'Futura Heavy';
  font-size: 15px;
  text-transform: uppercase;
}

.pane-upcoming-events-panel-pane-1 .views-field-title a {
  color: #313534;
}

.pane-upcoming-events-panel-pane-1 .views-field-title a:hover {
  color: #f0edec;
}

.pane-menu-menu-produce-marketplace ul {
  margin: 0;
  padding-left: 0;
}

.pane-menu-menu-produce-marketplace ul li {
  padding: 15px;
  font-family: 'Futura Book';
}

.pane-menu-menu-produce-marketplace ul li a:link,
.pane-menu-menu-produce-marketplace ul li a:visited {
  color: #313534;
}

.pane-menu-menu-produce-marketplace ul li.active-trail {
  background: #dbd8d7;
  border-left: 3px solid #3c7e44;
  font-weight: bold;
}

.page-node-add-produce-offer textarea,
.page-node-add-produce-request textarea {
  width: 100%;
}

.panels-flexible-region-produce_listings-center .views-row {
  border-bottom: 1px solid #dbd8d7;
  padding: 15px 0;
}

.panels-flexible-region-produce_listings-center .views-field-title a {
  color: #313534;
  font-weight: bold;
}

.panels-flexible-region-produce_listings-center .views-field-title a:hover {
  color: #56c663;
}

.panels-flexible-region-produce_listings-center .views-field-field-your-company-name,
.panels-flexible-region-produce_listings-center .views-field-field-produce-origin {
  font-family: 'Futura Heavy';
  color: #3c7e44;
}

.inline.links li.statistics_counter {
  display: none;
}

.page-node .field {
  margin-bottom: 20px;
}

@media all and (max-width: 1400px) {
  .menu__item {
    padding-right: 55px;
  }
}

@media all and (max-width: 1180px) {
  .header__region,
  .header__secondary-menu {
    display: none;
  }
}

@media all and (max-width: 850px) {
  .region-top #block-simpleads-ad-groups-117 {
    height: auto !important;
  }
  .region-top #block-simpleads-ad-groups-117 img {
    top: inherit;
  }
  body .layout-center .layout-3col {
    padding: 15px;
  }
  #block-block-5 {
    display: none;
  }
  #drawer-toggle-label {
    left: 20px;
    top: 0;
  }
  .panels-flexible-2 .panels-flexible-column-inside {
    padding: 0;
  }
  .panels-flexible-column {
    width: 100%;
  }
  .region-highlighted .block-simpleads {
    width: 47%;
  }
  .panels-flexible-column-last .pane-block, .panels-flexible-column-last .panel-pane {
    margin-top: 15px;
  }
  .pane-right-side-bar .panel-pane:not(:first-child) {
    float: left;
    width: 50%;
    max-width: none;
  }
  .panels-flexible-pr_main_layout .panels-flexible-column-inside {
    padding: 0;
  }
}

@media all and (max-width: 1150px) {
  .header__logo {
    width: 30%;
  }
}

@media all and (max-width: 850px) {
  .header__logo {
    width: 40%;
  }
}

@media all and (max-width: 600px) {
  .header__logo {
    width: 50%;
  }
}

@media all and (max-width: 435px) {
  .region-highlighted .block-simpleads {
    width: 100%;
    margin-bottom: 15px;
  }
  .header__logo {
    width: 70%;
  }
  .view-featured-produce .views-row,
  .pane-featured-topic-panel-pane-1 {
    width: 100%;
  }
  .pane-featured-topic-panel-pane-1 {
    min-height: 0;
    padding: 0;
  }
  .view-featured-produce .views-row,
  .pane-featured-topic-panel-pane-1:nth-child(2),
  .pane-featured-topic-panel-pane-1:nth-child(5),
  .pane-featured-topic-panel-pane-1:nth-child(8) {
    border: 0;
  }
  .pane-right-side-bar .panel-pane,
  .pane-right-side-bar .simplead-container img {
    width: 100%;
    margin-right: 0;
    max-width: none;
  }
  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-title {
    position: relative;
    bottom: 0;
    background: #313534;
    line-height: inherit;
    padding-bottom: 10px;
  }
  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-title a {
    font-size: 20px;
  }
  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-body {
    position: relative;
    bottom: 0;
    background: #313534;
    padding-bottom: 10px;
  }
  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-body a {
    font-size: 18px;
  }
  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-body p {
    margin: 0;
  }
  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide .views-field-field-produce {
    top: 20px;
    bottom: inherit;
  }
  .view-article-slideshow .views-slideshow-controls-bottom {
    display: none;
  }
  body footer .view-partner-logo ul li {
    margin: 0 10px;
  }
  .node-type-article .pane-node-links {
    margin-right: 15px;
    margin-bottom: 40px;
  }
  .node-type-article .pane-node-body {
    width: 100%;
    float: none;
  }
  .pane-right-side-bar .panel-pane:not(:first-child) {
    width: 100%;
  }
}

.sf-menu li:hover > ul, .sf-menu li.sfHover > ul {
  left: -575px;
  top: 2.5em;
  width: 1180px;
}

.region-top #block-simpleads-ad-groups-117 img {
  top: -75px;
}

.view-article-grid ul {
  display: grid;
  grid-template-columns: 32.5% 32.5% 32.5%;
  -moz-column-gap: 10px;
  /* Firefox */
  -webkit-column-gap: 10px;
  /* Safari and Chrome */
  list-style: none;
  padding: 0;
  margin: 0;
}

@media all and (max-width: 850px) {
  .view-article-grid ul {
    grid-template-columns: 50% 50%;
  }
}

@media all and (max-width: 442px) {
  .view-article-grid ul {
    grid-template-columns: 100%;
  }
}

.view-article-grid ul li {
  margin-bottom: 10px;
}

.view-article-grid ul li .views-field-field-image {
  position: relative;
}

.view-article-grid ul li .views-field-field-title {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
}

.view-article-grid ul li .views-field-field-title a {
  color: white;
}

.view-article-grid ul li .views-field-field-title a:hover {
  color: #72ef81;
}

.view-article-grid ul li .views-field-field-produce {
  position: absolute;
  bottom: 40%;
  height: 30px;
  padding: 3px;
  width: 100%;
}

.view-article-grid ul li .views-field-field-produce a {
  background-color: #56c663;
  border-radius: 5px;
  color: #f0edec;
  font-size: 0.8rem;
  padding: 3px 5px;
}

.view-article-grid ul li .views-field-field-produce a:hover {
  background-color: #3c7e44;
}

.view-article-grid ul li .views-field-field-produce a:hover {
  color: #f0edec;
}

.front #ad_groups_119 {
  margin-bottom: 13px;
}
