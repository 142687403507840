@import 'init';
// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base
// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 1
//
// Style guide: forms

// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 2
//
// Style guide: components

// Navigation
//
// Style guide: components.navigation

@import 'components/autocomplete/autocomplete';
@import 'components/box/box';
@import 'components/breadcrumb/breadcrumb';
@import 'components/button/button';
@import 'components/clearfix/clearfix';
@import 'components/collapsible-fieldset/collapsible-fieldset';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/form-item/form-item';
@import 'components/form-table/form-table';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/more-link/more-link';
@import 'components/nav-menu/nav-menu';
@import 'components/navbar/navbar';
@import 'components/pager/pager';
@import 'components/print-none/print-none';
@import 'components/progress-bar/progress-bar';
@import 'components/progress-throbber/progress-throbber';
@import 'components/resizable-textarea/resizable-textarea';
@import 'components/responsive-video/responsive-video';
@import 'components/skip-link/skip-link';
@import 'components/table-drag/table-drag';
@import 'components/tabs/tabs';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';

@import 'components/style_override';

.region-top {
  position: relative;
  margin: $spacing-large auto;
  max-width: 1180px;

  #block-simpleads-ad-groups-117 {
    overflow: hidden;
    transition: 1s all;
   // height:150px;

    img {
      transition: 1s all;
      top: -75px;
    }
  }

  #block-block-5 {
    position: absolute;
    right: 20px;
    z-index: 100;
    top: 10%;
    background: color(third-color-alt-3);
    padding: 10px 20px;
    cursor: pointer;
    color: color(red);
    border: 5px solid color(primary-color-alt);
    font-style: italic;
    font-family: 'Futura Heavy';
  }
}

.breadcrumb {
  border-bottom: 1px solid color(third-color-alt);
  padding-bottom: $spacing-tiny;
}



body {
	
  background-color: color(third-color);

  .header__region {

    .menu li {
      float: left;
    }
  }

  .layout-center {
    padding: 0;
    max-width: 1440px;
    overflow-x: hidden;

    .layout-3col {
      background-color: color(third-color-alt-3);
      margin: 0;
      padding-top: $spacing-large;
      padding-bottom: $spacing-large;
      transition: all 1s;
      position: relative;
      left: 0;

      .panels-flexible-column-2-main h2,  .panels-flexible-column-pr_main_layout-main h2 , .panels-flexible-column-2-main h2 a {
        border-top: 1px solid color(third-color-alt);
        border-bottom: 1px solid color(third-color-alt);
        color: color(primary-color);       
      }
      .panels-flexible-column-2-main h2 a
      {
      	 display:inline-block;
      	  width:100%;
      	  margin:-1px auto;
      }
      
    }

    .layout-3col__full {
      padding: 0;
      transition: all 1s;
      max-width: 1180px;
      margin: 0 auto;
      float: none;
    }
  }

  footer {
    background-color: color(third-color-alt-2);
    padding: $spacing-large 10%;
    text-align: center;
    font-size: map-get($font-size, s);

    h2 {
        text-align: center;
        display: table;
        width: 100%;
        font-size: map-get($font-size, m);
    }
    h2 > span,
    h2:before,
    h2:after {
        display: table-cell;
    }
    h2:before,
    h2:after {
        /*background: url(http://dummyimage.com/2x1/838384/fff&text=+) repeat-x center; */
        width: 50%;
        content: ' ';
    }
    h2 > span {
        white-space: nowrap;
        padding: 0 $spacing-normal;
    }

    .view-partner-logo {
      ul {
        li {
          display: inline-block;
          list-style-type: none;
          margin: 0 $spacing-large;
        }
      }
    }
  }

  .region-bottom {
    background-color: color(third-color-alt);
    padding: $spacing-large 10%;
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    font-size: map-get($font-size, s);
  }
}

.view-article-slideshow {
  position: relative;
  max-width: 850px;
  margin: 0 auto;

  .views-slideshow-controls-top {
    span {
      display: block;
      height: 85px;
      padding: 0;
      position: absolute;
      top: 40%;
      transition: all .2s linear;
      width: 70px;
      z-index: 10;

      a {
        display: none;
      }
    }

    .views_slideshow_controls_text_previous {
      background: url('/sites/all/themes/pr_theme/images/slideshow-left.svg') no-repeat;
      left: -8px;
    }

    .views_slideshow_controls_text_pause {
      display: none;
    }

    .views_slideshow_controls_text_next {
      background: url('/sites/all/themes/pr_theme/images/slideshow-right.svg') no-repeat;
      right: -20px;
    }
  }

  .views-slideshow-controls-bottom {
    text-align: center;
    background-color: color(body-bg);
    position: absolute;
    bottom: $spacing-tiny;
    width: 100%;
    z-index: 10;

    .views_slideshow_pager_field {
      display: inline-block;

      .views_slideshow_pager_field_item {
        display: inline-block;
        cursor: pointer;
        line-height: 0;
        overflow: hidden;
        position: relative;
        margin: 10px 0;

        .views-field-nid {
          display: block;
          height: 18px;
          width: 18px;
          float: left;
          background-color: color(third-color-alt-3);
          margin-left: 20px;
          border-radius: 18px;

          a {
            display: none;
          }
        }
      }

      .active .views-field-nid,
      .views-field-nid:hover {
        background-color: color(primary-color-alt);
      }

      .views_slideshow_pager_field_item:hover,
      .views_slideshow_pager_field_item.active {
        overflow: visible;
      }
    }
  }

  .views-slideshow-cycle-main-frame {
    //margin-bottom: $spacing-tiny;
    width: 100%;

    .views_slideshow_slide {
      .views-field-title {
        bottom: 80px;
        font-size: map-get($font-size, xxl);
        position: absolute;
        line-height: normal;
        padding: 0 50px;
        font-family: 'Futura Heavy';
        a:link,
        a:visited {
          color: color(third-color-alt-3);
          background-color: color(body-bg);
        }

        &:hover a {
          color: color(primary-color-alt);
        }
      }

      .views-field-body {
        position: absolute;
        bottom: 25px;
        padding: 0 50px;
        color: color(third-color-alt-3);
        margin: 0;

        p {
          background-color: color(body-bg);
        }
      }

      .image-field-caption {
        display: none;
      }

      .views-field-field-produce {
        font-family: 'Futura Heavy';
        bottom: 190px;
        padding: $spacing-tiny;
        margin-left: 50px;
        display: inline-block;
        position: absolute;
        text-transform: uppercase;
        background-color: color(primary-color-alt);
        color: color(third-color-alt-3);
        border-radius: $spacing-tiny;
        a:link,
        a:visited {
          color: color(third-color-alt-3);
        }

        &:hover {
          background-color: color(primary-color);
        }
      }
    }
  }
}

.pane-quicktabs-latest-popular-articles,
.pane-block-6 {
  background-color: color(accent-color-alt);
  border-radius: $spacing-tiny;
}

.pane-block-6 {
  position: relative;

  form {
    padding: $spacing-large;
  }

  .button {
    width: 35px;
    height: 35px;
    font-size: 38px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    top: -40px;
    right: -205px;
    position: relative;
  }

  input[type="email"] {
    padding: $spacing-small $spacing-normal;
    border: none;
    border-radius: $spacing-small;
    font-family: 'Futura Book';
  }
}

.pane-featured-country-panel-pane-1 {
  position: relative;
  border-radius: $spacing-tiny;
  overflow: hidden;

  h2 {
    display: none;
  }

  .views-field-name {
    font-family: 'Futura Heavy';
    background-color: color(third-color);
    width: 100%;
    text-align: center;
    padding: $spacing-small;
    text-transform: uppercase;
  }

  .views-field-title {
    position: absolute;
    background-color: color(body-bg);
    width: 100%;
    text-align: center;
    padding: $spacing-small;
    bottom: 0;
  }

  .views-field-field-region-image .field-content {
    line-height: 0;
  }

  img {
    width: 100%;
  }

  a:link,
  a:visited {
    color: color(third-color-alt-3);
  }
}

.quicktabs-wrapper {
  padding: $spacing-large 0 $spacing-tiny;

  .item-list {
    margin: 0 $spacing-large;
  }

  ul {
    border: 1px solid color(third-color-alt-3);
    text-transform: uppercase;
    font-family: 'Futura Heavy';
    padding: 0;
    height: 29px;

    li {
      float: left;
      text-align: center;
      font-size: map-get($font-size, s);

      a:link,
      a:visited {
        color: color(third-color);
      }
    }

    li.active {
      position: relative;
      background-color: color(third-color-alt-3);
    }

    li.active::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 16px solid color(third-color-alt-3);
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      position: absolute;
      left: 40%;
      top: 20px;
    }

    li:nth-child(1) {
      width: 45%;
    }

    li:nth-child(2) {
      width: 55%;
      position: relative;
    }

    li:nth-child(2)::before {
      content: '';
      width: 0;
      height: 0;
      border-right: 28px solid transparent;
      position: absolute;
      left: 0;
      top: 0;
    }

    li:nth-child(2):not(.active)::before {
      border-top: 28px solid color(third-color-alt-3);
    }

    li:nth-child(2).active::before {
      border-top: 28px solid color(accent-color-alt);
    }


  }

  ol {
    counter-reset:li; // Initiate a counter
    margin-left:0; // Remove the default left margin
    padding-left:0; // Remove the default left padding

    & > li::before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: -2em;
      box-sizing: border-box;
      width: 1.4em;
      height: 1.4em;
      border-top: 1px solid color(third-color);
      color: color(third-color-alt-3);
      background-color: color(third-color);
      border-radius: 50%;
      font-weight: bold;
      text-align: center;
    }
  }

  ol > li {
    margin-bottom: $spacing-normal;

    position:relative; // Create a positioning context
    margin: 0 0 6px 2em; // Give each list item a left margin to make room for the numbers
    padding: $spacing-tiny 0; // Add some spacing around the content
    list-style:none; // Disable the normal item numbering

    .views-field-created {
      font-size: map-get($font-size, s);
      color:#313534;
      text-transform: uppercase;
    }

    .views-field-title {
      a:link,
      a:visited {
        color: color(black);
      }
      a:hover {
        color: color(third-color-alt-3);
      }
    }
  }

  .view-footer {
    border-top: 1px solid color(third-color-alt-3);
    font-size: map-get($font-size, xs);

    p {
      margin: $spacing-tiny 0 0;
      text-align: center;

      a:link,
      a:visited {
        color: color(third-color-alt-3);
        font-family: 'Futura Heavy';
        text-transform: uppercase;
      }

      a:hover {
        color: color(primary-color);
      }
    }
  }
}

.view-featured-produce {
.view-content {
	overflow:hidden;
}

  .views-row {
    width: 47.5%;
    float: left;
    padding-bottom: $spacing-small;
    margin-bottom: $spacing-small;

    .views-field-field-image {
      float: left;
      margin-right: $spacing-normal;
    }

    .views-field-name {
      text-transform: uppercase;
      font-family: 'Futura Heavy';
      font-size: map-get($font-size, s);

      a {
        color: color(primary-color-alt);
      }

      a:hover {
        color: color(primary-color);
      }
    }

    .views-field-title {
      font-weight: bold;

      a {
        color: color(third-color);
      }

      a:hover {
        color: color(primary-color-alt);
      }
    }
  }

  .views-row-odd {
    border-right: 1px solid color(third-color-alt);
    margin-right: 5%;
  }
}

.pane-featured-topic-panel-pane-1 {
  width: 33%;
  float: left;
  padding: 0 $spacing-normal;
  min-height: 360px;

  h2 {
    margin: 0;
  }

  .image-field-caption {
    display: none;
  }

  .views-row {
    list-style: square;
    padding-bottom: $spacing-normal;
    overflow:hidden;

  }

  .views-row {
    list-style: none;
    clear: both;
    margin-bottom:10px;
    position: relative;
    left: -20px;
    .views-field-field-image {
      display: block;
      float:left;
      margin-right: $spacing-normal;
    }

  }

  a:link,
  a:visited {
    color: color(third-color);
    font-size:17px;
  }

  a:hover {
    color: color(primary-color);
  }

  &:nth-child(2),
  &:nth-child(5),
  &:nth-child(8) {
    border-left: 1px solid color(third-color-alt);
    border-right: 1px solid color(third-color-alt);
  }

  &:nth-child(1),
  &:nth-child(4),
  &:nth-child(4) {
    padding-left: 0;
  }

  &:nth-child(3),
  &:nth-child(6),
  &:nth-child(9) {
    padding-right: 0;
  }
}

.pane-recommended-reads-panel-pane-1,.view-recommended-reads {
   .view-content {
    display:grid;
    grid-template-columns:24% 24% 24% 24%;
    -moz-column-gap:10px; /* Firefox */
    -webkit-column-gap:10px; /* Safari and Chrome */


    @media all and (max-width: 850px) {
           grid-template-columns:50% 50% ;
    }
       @media all and (max-width: 442px) {
           grid-template-columns:100% ;
    }

   }
   .views-row {
    margin-bottom:10px;
   }

  .image-field-caption {
    display: none;
  }
}

.pane-node-created {
  display: inline-block;
  margin-right: $spacing-large;
  font-size: map-get($font-size, s);

  &::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/sites/all/themes/pr_theme/images/clock.svg') no-repeat 0 4px;
    position: relative;
    top: 4px;
  }
}

.pane-node-author {
  display: inline-block;
  font-size: map-get($font-size, s);

  &::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/sites/all/themes/pr_theme/images/author.svg') no-repeat 0 4px;
    position: relative;
    top: 2px;
  }
}

.pane-node-field-image {
  margin-top: $spacing-large;
}

.node-type-article {
  .image-field-caption {
    margin: 0;
    font-style: italic;
    font-size: map-get($font-size, s);

    p {
      margin: $spacing-tiny 0;
    }
  }

  .pane-node-body {
    width: 93%;
    float: right;
  }

  .pane-node-links {
    width: 40px;
    float: left;
    background-color: color(accent-color);
    margin-top: $base-line-height + 40px;
    position: relative;

    li {
      display: inline-block;
      margin-left: $spacing-small;
      padding: 0;
      margin-bottom: $spacing-small;
      width: 24px;
      height: 24px;
      overflow: hidden;

      img {
        width: 22px;
        height: 22px;
        margin-bottom: $spacing-small;
      }
    }

    &::before {
      border-bottom: 40px solid color(accent-color);
      content: '';
      width: 0;
      height: 0;
      border-left: 40px solid transparent;
      position: absolute;
      left: 0;
      top: -40px;
    }

    &::after {
      border-top: 40px solid color(accent-color);
      content: '';
      width: 0;
      height: 0;
      border-right: 40px solid transparent;
      position: absolute;
      left: 0;
      bottom: -40px;
    }

    .node-readmore,
    .comment-add,
    .comment_forbidden {
      display: none;
    }

    .comment-comments > a {
      display: block;
      background: url('/sites/all/themes/pr_theme/images/comment_add.svg') no-repeat;
      width: 24px;
      height: 48px;
      padding-top: 24px;
      overflow: hidden;
      padding-left: $spacing-tiny;
      font-size: map-get($font-size, s);
    }

    .print_html > a {
      display: block;
      background: url('/sites/all/themes/pr_theme/images/print.svg') no-repeat;
      width: 24px;
      height: 24px;
      font-size: 0;
    }

    .print_mail > a {
      display: block;
      background: url('/sites/all/themes/pr_theme/images/email.svg') no-repeat;
      width: 24px;
      height: 24px;
      font-size: 0;
    }

    .statistics_counter > span {
      display: block;
      background: url('/sites/all/themes/pr_theme/images/eye.svg') no-repeat;
      width: 24px;
      height: 48px;
      color: color(third-color-alt-3);
      padding-top: 24px;
      overflow: hidden;
      font-size: map-get($font-size, s);
      padding-left: 3px;
    }
  }

  .pane-node-field-image.bottom {
    .field-item {
      display: inline-block;
      margin-right: $spacing-small;
    }

    .image-field-caption {
      display: none;
    }
  }

  .field-type-taxonomy-term-reference {
    .field-item {
      display: inline-block;
      margin-right: $spacing-small;
    }
  }

  .pane-entity-field {
    margin-bottom: $spacing-normal;
  }
}

.page-taxonomy-term .view-article-by-term,
 .view-produce-listing ,
.view-latest-article,
 {
  position: relative;

  .view-header {
    position: absolute;
    left: $spacing-normal;
    top: $spacing-normal;
    color: color(third-color-alt-3);
    text-transform: uppercase;
    font-family: 'Futura Heavy';
  }

  .views-exposed-form {
    background-color: color(primary-color);

    .views-widget-sort-by {
      display: none;
    }

    .views-widget-sort-order ,.views-exposed-widget{
      float: right;
      padding: $spacing-normal;
    }

    label {
      float: left;
      color: color(third-color-alt-3);
      margin-right: $spacing-normal;
      display:none;
    }
  }
}

.page-taxonomy-term ,.view-latest-article,.view-all-articles,page-taxonomy-term{

  table {
    td {
      padding: $spacing-normal 0;
    }

    a {
      display: block;
    }

    .views-field-field-produce,.views-field-title {
      width: 60%!important;
      border-right: 1px solid color(third-color-alt-2);
      padding-right: $spacing-large;
//          word-break: break-all;
    }

    a:nth-child(1) { // Article Title
      font-family: 'Futura Heavy';
      font-size: map-get($font-size, l);
      color: color(third-color);
      &:hover {
        color: color(primary-color-alt);
      }
    }

    .views-field-created {
      text-transform: uppercase;
      font-family: 'Futura Book';
      font-size: map-get($font-size, s);
      padding-left: $spacing-large;
      width:25%;
    }
    .views-field-field-image {
    	width:15%;
    }
  }
}

.block-simpleads {
  display: inline-block;
}

#block-simpleads-ad-groups-118 {
  margin-right: $spacing-normal;
  margin-bottom: $spacing-normal;
}

.panels-flexible-column-last .pane-block,
.panels-flexible-column-last .panel-pane {
  margin-bottom: $spacing-normal;
}

.region-navigation {
overflow-y:scroll;
&::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
height:800px;
  width: 300px;
 position:absolute;
  border-top: 1px solid color(third-color-alt);
  z-index:-1;
  top:-1px;
  visibility:hidden;
}

#drawer-toggle {
  display: none;
}

#drawer-toggle-label {
  user-select: none;
  height: 50px;
  width: 50px;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 1s;
  left: 80px;
  top: $spacing-small;
  cursor: pointer;
  float: left;
}

/* adds our "hamburger" menu icon */
#drawer-toggle-label:before {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 24px;
  background: color(third-color-alt-3);
  left: 13px;
  top: 18px;
  box-shadow: 0 6px 0 color(third-color-alt-3), 0 12px 0 color(third-color-alt-3);
}


#drawer-toggle:checked ~ #drawer-toggle-label {
  background: color(third-color);
}


#drawer-toggle:checked ~ .sticky-wrapper header.header .region-navigation  {
	z-index:1000;
	 transition: 1.5s all;
	 left:-299px;
	visibility:visible;
}


#drawer-toggle:checked ~ .layout-3col,
#drawer-toggle:checked ~ .sticky-wrapper header {
   left: 300px;
   main {
     opacity: .5;
   }
}

.region-navigation #block-block-3 {
  background-color: color(primary-color);
  padding: $spacing-large;

  p {
    margin: 0;
  }
}

.region-navigation #block-search-form {
  background-color: color(primary-color);
  padding: $spacing-large;

  .form-item {
    margin: 0;
  }

  .form-submit {
    display: none;
  }

  .form-text {
    color: color(grey-dark);
    display: block;
    height: 30px;
    line-height: 20px;
    background: color('white') url('/sites/all/themes/pr_theme/images/search.svg') no-repeat scroll 3px 3px;
    padding: $spacing-tiny;
    border: 1px solid color(accent-color);
    border-radius: $spacing-tiny;
    padding-left: 25px;
    margin: 0 auto;
  }
}

.region-navigation .easytree-title {
  color: color(primary-color-alt-2);
  font-family: 'Futura Book';
  text-transform: capitalize;
}

.region-navigation .block-menu > ul > li > span > .easytree-title {
  font-family: 'Futura Heavy';
}

#block-menu-menu-drawer-menu > ul > li > span > .easytree-title {
  text-transform: uppercase;
}

#block-menu-menu-drawer-menu .easytree-container {
  background-color: color(primary-color);
  padding: 0;
}

#block-menu-menu-extra-drawer-menu .easytree-container {
  background-color: color(third-color);
  padding: 0;
}

.region-navigation span.easytree-expander {
  position: absolute;
  right: $spacing-small;
  background: url('/sites/all/themes/pr_theme/images/plus.svg') 0 0;
  width: 22px;
  height: 22px;

  &:hover {
    background-position: 0;
  }
}

.region-navigation .easytree-exp-e span.easytree-expander {
  position: absolute;
  right: $spacing-small;
  background: url('/sites/all/themes/pr_theme/images/minus.svg') 0 0;
  width: 22px;
  height: 22px;

  &:hover {
    background-position: 0;
  }
}

.region-navigation {

  .easytree-container.easytree-focused span.easytree-node.easytree-active,
  .easytree-container.easytree-focused span.easytree-node.easytree-selected,
  span.easytree-node.easytree-active:hover,
  span.easytree-node.easytree-selected:hover {
    background-color: transparent;
    border-color: color(primary-color-alt);
  }

  span.easytree-node {
    display: block;
    border: none;
    border-bottom: 1px solid color(third-color-alt);
    padding: $spacing-small;
    width: initial;

    &:hover {
      background-color: transparent;
    }
  }

  .block-block {
    color: color(third-color-alt);
    text-align: center;
    font-style: italic;
  }
}

.pane-right-side-bar {
  h2 {
    color: color(third-color-alt-3);
    text-align: center;
    margin: 0;
    border-bottom: 1px solid color(third-color-alt-3);
    padding: $spacing-tiny;
    font-size: map-get($font-size, s);
  }
}

.pane-quicktabs-produce-marketplace {
  background: color(accent-color);
  border-radius: $spacing-tiny;
  max-width: 300px;

  .quicktabs-wrapper ul li:nth-child(2).active::before {
    border-top: 28px solid color(accent-color);
  }
}

.pane-upcoming-events-panel-pane-1 {
  max-width: 300px;
  background: color(primary-color-alt);
  border-radius: $spacing-tiny;

  .view-upcoming-events {
    padding: $spacing-large;

    .views-row {
      margin-bottom: $spacing-normal;
      overflow: hidden;
    }
  }

  .views-field-field-event-logo {
    float: right;
  }

  .views-field-field-date {
    font-family: 'Futura Heavy';
    font-size: map-get($font-size, s);
    text-transform: uppercase;
  }

  .views-field-title a {
    color: color(third-color);

    &:hover {
      color: color(third-color-alt-3);
    }
  }
}

.pane-menu-menu-produce-marketplace {
  ul {
    margin: 0;
    padding-left: 0;

    li {
      padding: $spacing-normal;
      font-family: 'Futura Book';
    }

    li a:link,
    li a:visited {
      color: color(third-color);
    }

    li.active-trail {
      background: color(third-color-alt-2);
      border-left: 3px solid color(primary-color);
      font-weight: bold;
    }
  }
}

.page-node-add-produce-offer,
.page-node-add-produce-request {
  textarea {
    width: 100%;
  }
}

  .panels-flexible-region-produce_listings-center {
    .views-row {
      border-bottom: 1px solid color(third-color-alt-2);
      padding: $spacing-normal 0;
    }

    .views-field-title a {
      color: color(third-color);
      font-weight: bold;
    }

    .views-field-title a:hover {
      color: color(primary-color-alt);
    }

    .views-field-field-your-company-name,
    .views-field-field-produce-origin {
      font-family: 'Futura Heavy';
      color: color(primary-color);
    }
}

.inline.links li.statistics_counter {
  display: none;
}

.page-node .field {
  margin-bottom: $spacing-large;
}

@media all and (max-width: 1400px) {
  .menu__item {
    padding-right: 55px;
  }
}

@media all and (max-width: 1180px) {
  .header__region,
  .header__secondary-menu {
    display: none;
  }
}

@media all and (max-width: 850px) {
  .region-top #block-simpleads-ad-groups-117 {
    height: auto!important;

    img {
      top: inherit;
    }
  }



  body .layout-center .layout-3col {
    padding: $spacing-normal;
  }

  #block-block-5 {
    display: none;
  }

  #drawer-toggle-label {
    left: $spacing-large;
    top: 0;
  }

  .panels-flexible-2 .panels-flexible-column-inside {
    padding: 0;
  }

  .panels-flexible-column {
    width: 100%;
  }

  .region-highlighted .block-simpleads {
    width: 47%;
  }

  .panels-flexible-column-last .pane-block, .panels-flexible-column-last .panel-pane {
    margin-top: $spacing-normal;
  }

  .pane-right-side-bar {
    .panel-pane:not(:first-child) {
      float: left;
      width: 50%;
      max-width: none;
    }
  }

  .panels-flexible-pr_main_layout .panels-flexible-column-inside {
    padding: 0;
  }
}

@media all and (max-width:1150px) {
  .header__logo {
    width: 30%;
  }
}
@media all and (max-width:850px) {
  .header__logo {
    width: 40%;
  }
}

@media all and (max-width:600px) {
  .header__logo {
    width: 50%;
  }
}

@media all and (max-width: 435px) {



  .region-highlighted .block-simpleads {
    width: 100%;
    margin-bottom: $spacing-normal;
  }

  .header__logo {
    width: 70%;
  }

  .view-featured-produce .views-row,
  .pane-featured-topic-panel-pane-1 {
    width: 100%;
  }

  .pane-featured-topic-panel-pane-1 {
    min-height: 0;
    padding: 0;
  }

  .view-featured-produce .views-row,
  .pane-featured-topic-panel-pane-1:nth-child(2),
  .pane-featured-topic-panel-pane-1:nth-child(5),
  .pane-featured-topic-panel-pane-1:nth-child(8) {
    border: 0;
  }


  .pane-right-side-bar {
    .panel-pane,
    .simplead-container img {
      width: 100%;
      margin-right: 0;
      max-width: none;
    }
  }

  .view-article-slideshow .views-slideshow-cycle-main-frame .views_slideshow_slide {
    .views-field-title {
      position: relative;
      bottom: 0;
      background: color(third-color);
      line-height: inherit;
      padding-bottom: $spacing-small;

      a {
        font-size: map-get($font-size, l);
      }
    }

    .views-field-body {
      position: relative;
      bottom: 0;
      background: color(third-color);
      padding-bottom: $spacing-small;

      a {
        font-size: map-get($font-size, m);
      }

      p {
        margin: 0;
      }
    }

    .views-field-field-produce {
      top: $spacing-large;
      bottom: inherit;
    }

  }

  .view-article-slideshow .views-slideshow-controls-bottom {
    display: none;
  }

  body footer .view-partner-logo ul li {
    margin: 0 $spacing-small;
  }

  .node-type-article .pane-node-links {
    margin-right: $spacing-normal;
    margin-bottom: 40px;
  }

  .node-type-article .pane-node-body {
    width: 100%;
    float: none;
  }

  .pane-right-side-bar .panel-pane:not(:first-child) {
    width: 100%;
  }
}

.sf-menu li:hover > ul, .sf-menu li.sfHover > ul {
left:-575px;
top:2.5em;
width:1180px;
}


.region-top #block-simpleads-ad-groups-117 {
 img {
 top:-75px;
}
}



.view-article-grid {
   ul {
       display:grid;
       grid-template-columns:32.5% 32.5% 32.5%;
	-moz-column-gap:10px; /* Firefox */
	-webkit-column-gap:10px; /* Safari and Chrome */
       list-style: none;
       padding:0;
       margin:0;
       @media all and (max-width: 850px) {
           grid-template-columns:50% 50% ;
       }
       @media all and (max-width: 442px) {
           grid-template-columns:100% ;
       }
    li {
	margin-bottom:10px;
	.views-field-field-image {
	position:relative;
   
}


	.views-field-field-title {
	position:absolute;	
	bottom:0;
        background-color: rgba(0,0,0,0.7);
	padding:5px;
	a {
	color:white;
	&:hover {
	color:#72ef81;
}
	}
	}
       .views-field-field-produce {
		position:absolute;   
    bottom:40%;
    height: 30px;
    padding:3px;
    width:100%; 
    a {
        background-color: #56c663;
	border-radius:5px;
        color: #f0edec;
	font-size:0.8rem;
        padding:3px 5px;
	&:hover  {
	background-color: #3c7e44;
}
    &:hover {
	color:#f0edec;
}
}
}


     }
   }

}


.front #ad_groups_119 {
    margin-bottom:13px;
}

