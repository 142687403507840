// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.hbs
//
// Style guide: components.header

header.header {
  background-color: color(primary-color);
  border-top: 1px solid color(third-color-alt);
  border-right: 1px solid color(third-color-alt);
  position: relative;
  transition: all 1s;
  height: 74px;
}

.header,
%header {
  @extend %clearfix;

  // Wrapping link for logo.
  &__logo {
    float: left;
    padding: 0;
    display: block;
    margin-left: 9%;
    width: 25%;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    position: absolute;
    right: 0;
    z-index: 1000;
    background-color: color(third-color);

    @include rtl() {
      float: left;
    }

    li {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    background-color: color(third-color);
    clear: none;
    float: right;
    padding-left: 72px;
    position: relative;
  }

  &__region::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 72px solid color(primary-color);
    border-right: 72px solid transparent;
    position: absolute;
    left: 0;
  }

  .menu,
  .sf-menu.menu {
    @include margin(1 0);
    height: 25px;
    line-height: 25px;
  }

}
