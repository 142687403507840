// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.hbs
//
// Style guide: components.navigation.nav-menu

.nav-menu,
%nav-menu {

  &__item {
    list-style-type: none;
    padding-right: 75px;
    text-transform: uppercase;

    @if support-for(ie, 8) {
      .lt-ie9 & {
        list-style-image: image-url('nav-menu/leaf.png');
      }
    }

    &.is-expanded,
    &--is-expanded {
      list-style-image: image-url('nav-menu/expanded.svg');
      list-style-type: circle;

      @if support-for(ie, 8) {
        .lt-ie9 & {
          list-style-image: image-url('nav-menu/expanded.png');
        }
      }
    }

    &.is-collapsed,
    &--is-collapsed {
      list-style-image: image-url('nav-menu/collapsed.svg');
      list-style-type: disc;

      @if support-for(ie, 8) {
        .lt-ie9 & {
          list-style-image: image-url('nav-menu/collapsed.png');
        }
      }

      @include rtl() {
        list-style-image: image-url('nav-menu/collapsed-rtl.svg');

        @if support-for(ie, 8) {
          .lt-ie9 & {
            list-style-image: image-url('nav-menu/collapsed-rtl.png');
          }
        }
      }
    }
  }

  &__link {
    &.is-active,
    &--is-active {
      color: color(menu-active);
    }
  }
}

//
// Drupal selectors.
//

.menu__item {
  @extend %nav-menu__item;
  font-family: 'Futura Heavy';

  &.is-expanded {
    @extend %nav-menu__item--is-expanded;
  }

  &.is-collapsed {
    @extend %nav-menu__item--is-collapsed;
  }
}

// The active item in a Drupal menu.
.menu a.active {
  @extend %nav-menu__link--is-active;
}

.menu a,
.links a {
  color: color(menu-normal);
}

#menu-424-1 > a {
  display: block;
  background: url('/sites/all/themes/pr_theme/images/home.svg') no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.menu-2 > a {
  display: block;
  background: url('/sites/all/themes/pr_theme/images/user.svg') no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.menu-503 > a {
  display: block;
  background: url('/sites/all/themes/pr_theme/images/search.svg') no-repeat;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  background-color: color(third-color-alt-3);
  padding: $spacing-large;
  box-shadow: 0 3px $spacing-tiny color(third-color);

  a:link,
  a:visited {
    font-family: 'Futura Book';
    color: color(third-color);
    text-transform: capitalize;
    padding: $spacing-tiny;
  }

  a:hover {
    background-color: color(primary-color-alt);
    color: color(menu-normal);
  }
}

.sf-with-ul {
  position: relative;
}

.sfHover .sf-with-ul::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  bottom: -20px;
  border-bottom: 10px solid color(third-color-alt-3);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  box-shadow: 0 0 $spacing-small color(third-color);
}

.sf-menu h3 {
  font-size: map-get($font-size, xs);
  color: color(primary-color);
  border-bottom: 1px solid color(primary-color);
  margin-bottom: $spacing-small;
}

a > .sf-sub-indicator {
  top: 6%;
  right: -1em;
  background: url('/sites/all/themes/pr_theme/images/down.svg') 0 0 no-repeat;
  height: 18px;
  width: 18px;
}

li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator {
  background-position: 0 0;
}
